<template>
  <v-card flat>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <!-- ปุ่มเลือกสินค้า -->
          <v-select dense outlined hide-details label="ช่วงเวลา" :items="year" class="me-2"></v-select>
          <date-picker v-model="time3" range placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
        </div>
        <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
          <v-icon class="me-2">
            {{ icons.mdiCheck }}
          </v-icon>
          รันรีพอร์ต
        </v-btn>
      </div>
      <div class="mt-4 d-flex align-center filter">
        <h3 class="me-2">ตัวกรอง</h3>
        <v-text-field
          label="ค้นหาชื่อลูกค้า"
          type="text"
          outlined
          dense
          hide-details
          placeholder="ค้นหาชื่อลูกค้า"
        ></v-text-field>
      </div>
      <hr class="mt-5 mb-5" />
      <!-- Content 1 -->
      <h2 class="text-center">ภาพรวมการขายสินค้า</h2>
      <p class="text-center mb-6">สรุปรายการสินค้าที่ทำยอดขาย และสร้างกำไรให้บริษํท</p>
      <v-row>
        <v-col md="4" sm="4" cols="12" class="warpper_show" @click="clickWapper(1)">
          <p class="mb-0">รายการขาย</p>
          <h3>{{ dataMock.listSell.total }}</h3>
          <div class="d-flex justify-space-between">
            <p>รายการที่ขายไปทั้งหมด</p>
            <p v-if="dataMock.listSell.average > 0" class="upColor">
              <v-icon class="upColor">
                {{ icons.mdiMenuUp }}
              </v-icon>
              {{ dataMock.listSell.average }} %
            </p>
            <p v-if="dataMock.listSell.average < 0" class="downColor">
              <v-icon class="downColor">
                {{ icons.mdiMenuDown }}
              </v-icon>
              {{ dataMock.listSell.average }} %
            </p>
          </div>
        </v-col>
        <v-col md="4" sm="4" cols="12" class="warpper_show" @click="clickWapper(2)">
          <p class="mb-0">ยอดขาย</p>
          <h3>{{ dataMock.sales.total }}</h3>
          <div class="d-flex justify-space-between">
            <p class="mb-0">ยอดขายรวมที่ขายได้</p>
            <p v-if="dataMock.sales.average > 0" class="upColor">
              <v-icon class="upColor">
                {{ icons.mdiMenuUp }}
              </v-icon>
              {{ dataMock.sales.average }} %
            </p>
            <p v-if="dataMock.sales.average < 0" class="downColor">
              <v-icon class="downColor">
                {{ icons.mdiMenuDown }}
              </v-icon>
              {{ dataMock.sales.average }} %
            </p>
          </div>
        </v-col>
        <v-col md="4" sm="4" cols="12" class="warpper_show" @click="clickWapper(3)">
          <p class="mb-0">กำไรทั้งหมด</p>
          <h3>{{ dataMock.profit.total }}</h3>
          <div class="d-flex justify-space-between">
            <p class="mb-0">กำไรรวมที่ขายได้</p>
            <p v-if="dataMock.profit.average > 0" class="upColor">
              <v-icon class="upColor">
                {{ icons.mdiMenuUp }}
              </v-icon>
              {{ dataMock.profit.average }} %
            </p>
            <p v-if="dataMock.profit.average < 0" class="downColor">
              <v-icon class="downColor">
                {{ icons.mdiMenuDown }}
              </v-icon>
              {{ dataMock.profit.average }} %
            </p>
          </div>
        </v-col>
      </v-row>
      <div v-if="listSell" class="mt-5">
        <ConCludeList />
      </div>
      <div v-if="sales" class="mt-5">
        <ConCludeSales />
      </div>
      <div v-if="profitAll" class="mt-5">
        <ConCludeProfit />
      </div>
    </v-card-text>
    <!-- End Content 1 -->

    <!-- Content 2 -->
    <v-card-text>
      <hr class="mt-5 mb-5" />
      <v-row>
        <v-col sm="3" md="3" cols="6">
          <div class="contentClick" @click="clickChange(1)">
            <h3>รายได้</h3>
            <p class="mb-0">ยอดขายที่เกิดจากการขายสินค้า</p>
          </div>
        </v-col>
        <v-col sm="3" md="3" cols="6">
          <div class="contentClick" @click="clickChange(2)">
            <h3>กำไรการขาย</h3>
            <p class="mb-0">ราคาขายหักด้วยต้นทุนสินค้า</p>
          </div>
        </v-col>
        <v-col sm="3" md="3" cols="6">
          <div class="contentClick" @click="clickChange(3)">
            <h3>โลเคชั่น</h3>
            <p class="mb-0">ยอดขายตามภูมิภาค</p>
          </div>
        </v-col>
        <v-col sm="3" md="3" cols="6">
          <div class="contentClick" @click="clickChange(4)">
            <h3>เครดิต</h3>
            <p class="mb-0">ยอดขายแบ่งตามเครดิต</p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- content income -->
    <v-card-text v-if="contentIncome">
      <hr class="mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>ยอดขายลูกค้า</h3>
          <p class="mb-0">ลูกค้าที่ทำการซื้อขาย และสร้างกำไรให้บริษัท สรุปรายการขายข้อมูล</p>
        </div>
        <p class="mb-0 font-weight-bold">
          จำนวนลูกค้า <span>{{ countCustomer }} คน</span>
        </p>
      </div>
      <hr class="mt-5 mb-5" />
      <ProfitCudetomer />
      <hr class="mt-5 mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>รายการยอดขายลูกค้า</h3>
          <span class="font-weight-bold">{{ name }}</span>
          <p class="mb-0">1 ม.ค. {{ new Date().getFullYear() }} - 31 ธ.ค. {{ new Date().getFullYear() }}</p>
        </div>
        <div>
          <v-text-field label="ค้นหา" type="text" outlined dense hide-details placeholder="ค้นหา"></v-text-field>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <ListCustomer />
    </v-card-text>
    <!-- end content income -->

    <!-- content profit -->
    <v-card-text v-if="contentProfit">
      <hr class="mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>กำไรการขาย</h3>
          <p class="mb-0">ราคาขายหักด้วยต้นทุนสินค้า</p>
        </div>
        <p class="mb-0 font-weight-bold">
          จำนวนลูกค้า <span>{{ countCustomer }} คน</span>
        </p>
      </div>
      <hr class="mt-5 mb-5" />
      <ProfitSell />
      <hr class="mt-5 mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>รายการกำไรการขาย</h3>
          <span class="font-weight-bold">{{ name }}</span>
          <p class="mb-0">1 ม.ค. {{ new Date().getFullYear() }} - 31 ธ.ค. {{ new Date().getFullYear() }}</p>
        </div>
        <div>
          <v-text-field label="ค้นหา" type="text" outlined dense hide-details placeholder="ค้นหา"></v-text-field>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <ProfitListSell />
    </v-card-text>
    <!-- end content profit -->

    <!-- content location -->
    <v-card-text v-if="contentLocation">
      <hr class="mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>โลเคชั่น</h3>
          <p class="mb-0">ยอดขายตามภูมิภาค</p>
        </div>
        <p class="mb-0 font-weight-bold">
          จำนวนลูกค้า <span>{{ countCustomer }} คน</span>
        </p>
      </div>
      <hr class="mt-5 mb-5" />
      <Location />
      <hr class="mt-5 mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>รายการโลเคชั่น</h3>
          <span class="font-weight-bold">{{ name }}</span>
          <p class="mb-0">1 ม.ค. {{ new Date().getFullYear() }} - 31 ธ.ค. {{ new Date().getFullYear() }}</p>
        </div>
        <div>
          <v-text-field label="ค้นหา" type="text" outlined dense hide-details placeholder="ค้นหา"></v-text-field>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <LocationList />
    </v-card-text>
    <!-- end content location -->

    <!-- content credit -->
    <v-card-text v-if="contentCredit">
      <hr class="mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>เครดิต</h3>
          <p class="mb-0">ยอดขายแบ่งตามเครดิตเทอม</p>
        </div>
        <p class="mb-0 font-weight-bold">
          จำนวนลูกค้า <span>{{ countCustomer }} คน</span>
        </p>
      </div>
      <hr class="mt-5 mb-5" />
      <Credit />
      <hr class="mt-5 mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>รายการเครดิต</h3>
          <span class="font-weight-bold">{{ name }}</span>
          <p class="mb-0">1 ม.ค. {{ new Date().getFullYear() }} - 31 ธ.ค. {{ new Date().getFullYear() }}</p>
        </div>
        <div>
          <v-text-field label="ค้นหา" type="text" outlined dense hide-details placeholder="ค้นหา"></v-text-field>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <CreditList />
    </v-card-text>
    <!-- end content credit -->
    <!-- End Content 2 -->
  </v-card>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mdiCheck, mdiMenuUp, mdiMenuDown } from '@mdi/js'
import ConCludeList from './conclude-sales/conclude_lists.vue'
import ConCludeSales from './conclude-sales/conclude_sales.vue'
import ConCludeProfit from './conclude-sales/conclude_profit.vue'
import ProfitCudetomer from './customer/CustomerProfitCudetomer.vue'
import ListCustomer from './customer/CustomerListCustomer.vue'
import ProfitSell from './customer/ProfitSell.vue'
import ProfitListSell from './customer/ProfitListSell.vue'
import Location from './customer/Location.vue'
import LocationList from './customer/LocationList.vue'
import Credit from './customer/Credit.vue'
import CreditList from './customer/CreditList.vue'

export default {
  components: {
    DatePicker,
    ConCludeList,
    ConCludeSales,
    ConCludeProfit,
    ProfitCudetomer,
    ListCustomer,
    ProfitSell,
    ProfitListSell,
    Location,
    LocationList,
    Credit,
    CreditList,
  },
  setup() {
    const year = [
      'เลือกช่วงเวลา',
      'กำหนดเอง',
      'เดือนนี้',
      'ไตรมาสนี้',
      ' ปีปัจจุบัน',
      'ปีก่อนหน้า',
      'เดือนนี้ถึงวันนี้',
      'ไตรมาสนี้ถึงวันนี้',
      'ปีนี้ถึงวันนี้',
      'ปีงบประมาณปัจจุบัน',
      'ปีงบประมาณก่อนหน้า',
      '12 เดือนย้อนหลัง',
    ]
    const dataMock = {
      listSell: {
        total: 500000,
        average: 2,
      },
      sales: {
        total: 1000000,
        average: 10,
      },
      profit: {
        total: 400000,
        average: -1,
      },
    }

    return {
      year,
      dataMock,
      icons: {
        mdiCheck,
        mdiMenuUp,
        mdiMenuDown,
      },
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      name: 'Case',
      listSell: true,
      sales: false,
      profitAll: false,
      countCustomer: 2,
      contentIncome: true,
      contentProfit: false,
      contentLocation: false,
      contentCredit: false,
    }
  },

  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
    clickWapper(id) {
      if (id === 2) {
        this.listSell = false
        this.sales = true
        this.profitAll = false
      } else if (id === 3) {
        this.listSell = false
        this.sales = false
        this.profitAll = true
      } else {
        this.listSell = true
        this.sales = false
        this.profitAll = false
      }
    },
    clickChange(id) {
      if (id === 2) {
        this.contentIncome = false
        this.contentProfit = true
        this.contentLocation = false
        this.contentCredit = false
      } else if (id === 3) {
        this.contentIncome = false
        this.contentProfit = false
        this.contentLocation = true
        this.contentCredit = false
      } else if (id === 4) {
        this.contentIncome = false
        this.contentProfit = false
        this.contentLocation = false
        this.contentCredit = true
      } else {
        this.contentIncome = true
        this.contentProfit = false
        this.contentLocation = false
        this.contentCredit = false
      }
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.warpper_show {
  border-left: 1px solid #e5e5e5;
  transition: all 0.1s ease-in;
  cursor: pointer;
  max-height: 7rem;
  /* background-color: aquamarine; */
}
.warpper_show:hover {
  border-bottom: 2px solid #8dbc8a;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.upColor {
  color: #8dbc8a;
}
.downColor {
  color: red;
}
.contentClick {
  border: #e5e5e5 1px solid;
  border-radius: 4px;
  margin: 1rem 0;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.contentClick:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transform: translateZ(2);
}
.filter {
  width: 30%;
}
</style>
